export {}

const isDarkMode = !!localStorage.getItem("isDarkMode")

const mobilePosition = document.querySelector(".m-nav__switch-theme")
const desktopPosition = document.querySelector(".g-switch-theme-desktop__container")
const switchTheme = document.querySelector(".f-switch-theme")

const mobileQuery = window.matchMedia("(max-width: 74.999em)") // Change to your needs

if (switchTheme) {
  const handleMobileChange = (mediaQueryList: MediaQueryListEvent | MediaQueryList) => {
    if (mediaQueryList.matches) {
      mobilePosition?.append(switchTheme)
    } else {
      desktopPosition?.append(switchTheme)
    }
  }
  mobileQuery.addEventListener("change", handleMobileChange)
  handleMobileChange(mobileQuery) // Init

  const input = switchTheme.querySelector<HTMLInputElement>(".f-switch__input")

  if (input) {
    const enable = () => {
      document.body.classList.add("--dark-theme")
      localStorage.setItem("isDarkMode", "enabled")
    }
    const disable = () => {
      document.body.classList.remove("--dark-theme")
      localStorage.removeItem("isDarkMode")
    }

    const handle = () => {
      input.checked ? disable() : enable()
    }

    input.checked = !isDarkMode

    switchTheme.addEventListener("change", handle)
    handle()
  }
}
