import "css/style.css"
import {lazyLoading} from "./lazyload/lazyload-init"
import "./mask-lazyload/mask-lazyload"
import "@components/main-nav"
import "@components/main-header/main-header"
import "@components/items/menu-item/menu-item"
import "@components/fields/switch-theme-field/switch-theme-field"
import "./data-layer/data-layer"

window.lazyloading = lazyLoading // add lazyloading to global window object

const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0

async function loadFancybox() {
  if (document.querySelectorAll("[data-fancybox]").length) {
    await import("./fancybox/fancybox")
  }
}
async function loadMain() {
  await import("./main")
  loadFancybox()
}
loadMain()

async function loadFileInput() {
  if (document.querySelectorAll(".f-file").length) {
    await import("@components/fields/file-field/file-field")
  }
}
loadFileInput()

async function loadPasswordField() {
  if (document.querySelectorAll(".f-text.--password").length) {
    await import("@components/fields/text-field/text-field--password")
  }
}
loadPasswordField()

async function loadAlerts() {
  if (document.querySelectorAll(".i-alert").length) {
    await import("@components/items/alert-item/alert-item")
  }
}
loadAlerts()

async function loadNotification() {
  if (document.querySelectorAll(".i-notification").length) {
    await import("@components/items/notification-item/notification-item")
  }
}
loadNotification()

async function loadColorItem() {
  if (document.querySelectorAll(".i-color").length) {
    await import("@components/items/color-item/color-item")
  }
}
loadColorItem()

async function loadFaqs() {
  if (document.querySelectorAll(".s-faq").length) {
    await import("@components/groups/faqs-group/faqs-group")
  }
}
loadFaqs()

async function loadSwiper() {
  if (document.querySelectorAll(".swiper").length) {
    await import("./swiper/swiper")
  }
}
loadSwiper()

async function loadOverlayScrollbars() {
  if (document.querySelectorAll(".u-scrollbar").length) {
    await import("./overlayscrollbars")
  }
}
loadOverlayScrollbars()

async function loadBaseForm() {
  if (document.querySelectorAll("form.--validate").length) {
    await import("@components/base-form/base-form")
  }
}
loadBaseForm()

async function loadTextField() {
  if (document.querySelectorAll(".f-text, .f-textarea").length) {
    await import("@components/fields/text-field/text-field")
  }
}
loadTextField()

async function loadTextareaField() {
  if (document.querySelectorAll(".f-textarea").length) {
    await import("@components/fields/textarea-field/textarea-field")
  }
}
loadTextareaField()

async function loadCursor() {
  if (document.querySelectorAll(".--js-cursor-focus-elements").length && !isTouchDevice) {
    await import("@components/cursor/cursor")
  }
}
loadCursor()

async function loadPillsAnimated() {
  if (document.querySelectorAll(".g-pills").length) {
    await import("@components/groups/pills-group/pills-group")
  }
}
loadPillsAnimated()

async function loadUspItem() {
  if (document.querySelectorAll(".g-usps").length) {
    await import("@components/groups/usps-group/usps-group")
  }
}
loadUspItem()

async function loadCommendations() {
  if (document.querySelectorAll(".g-commendations").length) {
    await import("@components/groups/commendations-group/commendations-group")
  }
}
loadCommendations()

async function loadAboutImages() {
  if (document.querySelectorAll(".i-about-image").length) {
    await import("@components/items/about-image-item/about-image-item")
  }
}
loadAboutImages()

async function loadPowerGlitch() {
  if (document.querySelectorAll(".glitch").length) {
    await import("./power-glitch/power-glitch")
  }
}
loadPowerGlitch()

async function loadScramble() {
  if (document.querySelectorAll(".--js-scramble, .--js-scramble-hover").length) {
    await import("./scramble/scramble")
  }
}
loadScramble()

async function loadReferenceItem() {
  if (document.querySelectorAll(".i-reference").length) {
    await import("@components/items/reference-item/reference-item")
  }
}
loadReferenceItem()

async function loadFaqItem() {
  if (document.querySelectorAll(".i-faq").length) {
    await import("@components/items/faq-item/faq-item")
  }
}
loadFaqItem()

async function loadPostItem() {
  if (document.querySelectorAll(".i-post").length) {
    await import("@components/items/post-item/post-item")
  }
}
loadPostItem()

async function loadServiceIntroSection() {
  if (document.querySelectorAll(".s-service-intro").length) {
    await import("@components/sections/service-intro-section/service-intro-section")
  }
}

loadServiceIntroSection()

async function loadTestomonialsInfinite() {
  if (document.querySelectorAll(".g-testimonials.--infinite").length) {
    await import("@components/groups/testimonials-group/testimonials-group--infinite")
  }
}

loadTestomonialsInfinite()

async function loadScrollIndicator() {
  if (document.querySelectorAll(".g-testimonials.--infinite").length) {
    await import("@components/scroll-indicator/scroll-indicator")
  }
}

loadScrollIndicator()

async function loadDescriptionDecoration() {
  if (document.querySelectorAll(".c-desc-decoration").length) {
    await import("@components/description-decoration/description-decoration")
  }
}
loadDescriptionDecoration()

async function loadReferencesLoadMore() {
  if (document.querySelectorAll(".--js-references-load-more").length) {
    await import("./references-load/references-load")
  }
}
loadReferencesLoadMore()

async function loadPostsList() {
  if (document.querySelectorAll(".s-posts-list").length) {
    await import("./posts-list/posts-list")
  }
}
loadPostsList()

async function loadModal() {
  if (document.querySelectorAll(".c-modal").length) {
    await import("@components/modal/modal")
  }
}
loadModal()

async function loadShuffle() {
  if (!isTouchDevice) {
    await import("./shuffle/shuffle")
  }
}
loadShuffle()

async function loadExternalSvgLoader() {
  if (document.querySelectorAll(".c-svg").length) {
    await import("@components/svg")
  }
}
loadExternalSvgLoader()
