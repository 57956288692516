export {}

interface DataLayerEventOptions {
  [key: string]: unknown
}

export const pushToDataLayer = (eventName: string | null, options: DataLayerEventOptions = {}) => {
  window.dataLayer = window.dataLayer || []
  if (eventName) {
    window.dataLayer.push({
      event: eventName,
      ...options,
    })
  } else {
    window.dataLayer.push({
      ...options,
    })
  }
}

const darkModeEvent = () => {
  const themeInputs = document.querySelectorAll<HTMLInputElement>(".f-switch-theme .f-switch__input")
  themeInputs.forEach((input) => {
    input.addEventListener("change", () => {
      pushToDataLayer("dark_mode", {dark_modeStatus: input.checked ? "off" : "on"})
    })
  })
}

darkModeEvent()

const newsletterSignupEvent = () => {
  const forms = document.querySelectorAll<HTMLFormElement>(".f-newsletter")
  forms.forEach((form) => {
    form.addEventListener("submit", () => {
      if (form.checkValidity()) {
        const email = form.querySelector<HTMLInputElement>("input[type=email]")?.value
        if (email) pushToDataLayer(null, {user_email: email})
        pushToDataLayer("newsletter_signup")
      }
    })
  })
}

newsletterSignupEvent()

const popupLeadEvent = () => {
  const popupButtons = document.querySelectorAll<HTMLElement>("[data-modal-target=m-contact-form]")
  popupButtons.forEach((button) => {
    button.addEventListener("click", () => {
      pushToDataLayer("popup_lead")
    })
  })
}

popupLeadEvent()

const shareEvent = () => {
  const shareLinks = document.querySelectorAll<HTMLElement>(".s-share .link")
  shareLinks.forEach((link) => {
    link.addEventListener("click", () => {
      pushToDataLayer("share", {share_method: link.title})
    })
  })
}

shareEvent()
