type maskObject = {
  src: string
  breakpoints?: {
    src: string
    query: string
  }[]
}

const setMask = (element: HTMLElement, src: string) => {
  element.style.mask = `url(${src})`
  element.style.webkitMask = `url(${src})`

  element.style.maskSize = `contain`
  element.style.maskRepeat = `no-repeat`

  element.style.webkitMaskSize = `contain`
  element.style.webkitMaskRepeat = `no-repeat`
}

const maskInit = (maskElement: HTMLElement) => {
  const mask = JSON.parse(maskElement.dataset.mask ?? "null") as maskObject | null

  if (!mask) return

  setMask(maskElement, mask.src)

  if (!mask.breakpoints) return

  mask.breakpoints.forEach((breakpoint) => {
    const mediaQuery = window.matchMedia(breakpoint.query)

    const handleMediaChange = () => {
      if (!mediaQuery.matches) return
      setMask(maskElement, breakpoint.src)
    }

    mediaQuery.addEventListener("change", handleMediaChange)
    handleMediaChange()
  })
}

const observerSettings = {
  threshold: 0,
  rootMargin: "0px 0px 300px 0px",
}

document.querySelectorAll<HTMLElement>("[data-mask]").forEach((maskElement) => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return

      maskInit(maskElement)

      observer.unobserve(maskElement)
    })
  }, observerSettings)

  observer.observe(maskElement)
})
